// Germany
export const locale = {
  ok: 'OK',
  cancel: 'Stornieren',
  register: 'Registrierung',
  login: 'Anmelden',
  welcome: 'Willkommen zurück',
  logout: 'Logout',
  submit: 'Einreichen',
  contact_us: 'KONTAKTIERE UNS',
  become_an_organizer: 'TICKETVERKAUF JETZT STARTEN',
  support: 'UNTERSTÜTZUNG',
  support_description: 'Wir klären schnellstmöglich jede Frage rund um eventbox. Wir erklären dir gerne wie du deine Privatsphäre schützt oder was du tun musst wenn dein Account gehackt wurde. Bei Fragen und Unklarheiten bitten wir dich direkt den Veranstalter selbst zu kontaktieren.',
  companis_: 'UNTERNEHMEN:',
  about_us: 'Über Uns',
  news: 'Nachrichten',
  contact: 'Kontakt',
  jobs: 'Jobs',
  programs_: 'PROGRAMME:',
  sponsorship: 'Sponsoring',
  support_: 'SUPPORT:',
  help_center: 'Hilfezentrum',
  security_center: 'Sicherheitszentrum',
  community_guidelines: 'Community Richtlinien',
  legal_: 'RECHTLICHES:',
  eventbox_cookie_policy: 'eventbox Cookie Richtlinien',
  imprint: 'Impressum',
  terms_service: 'Nutzungsbedingungen',
  privacy_policy: 'AGB + Datenschutzerklärung',
  business_user_agreement: 'Händlervereinbarung',
  about_header: 'eventbox - share your Moments\n& makes the World better!',
  about_description: 'Unsere Vision ist es, dass die\n' +
    'Menschen auf der ganzen Welt die\n' +
    'schönen und unvergesslichen\n' +
    'Eventerlebnisse auf eventbox\n' +
    'festhalten und anderen Menschen\n' +
    'davon faszinieren können!\n\n' +
    'Willst du auch inspiriert werden?\n' +
    'Dann lade dir die eventbox App herunter\n' +
    'und sei ein Teil von uns.',
  contact_partner: 'Werde Partner',
  contact_inquiries: 'Presseanfragen',
  contact_privacy: 'Datenschutz',
  jobs_title: 'Challenge yourself. Join eventbox.',
  jobs_header: 'Karriere bei eventbox\nWachse in einem starken Team',
  jobs_looking_for: 'Derzeit suchen wir:',
  sponsorship_description: 'Werben auf eventbox.\n' +
    'Profitiere von vielen Möglichkeiten auf eventbox zu werben.\n' +
    'Präsentiere dein Produkt einer grossen Anzahl von potenziellen Kunden weltweit.\n' +
    'Für Anfragen wende dich bitte an unsere ' +
    '<a href="mailto:info@eventboxde.com">' +
    '<u class="color-white">Ads-Abteilung hier</u>' +
    '</a>',
  cookie_description: 'Diese Webseite benutzt Cookies. Wenn du diese Webseite weiter nutzt, gehen wir von deinem Einverständnis aus.',
  see_all_events: 'Alle Events',
  no_data_found: 'Keine Daten gefunden!',
  please_enter: 'Bitte eingeben ',
  email: 'E-Mail',
  repeat_email: 'E-Mail wiederholen',
  phone: 'Telefon',
  username_or_email: 'Username oder Emailadresse',
  password: 'Passwort',
  forgot_password: 'Passwort vergessen?',
	do_not_have_an_account: 'Du hast noch keinen Account?',
  select_account_type: 'Wähle bitte aus',
  normal_user: 'Normal User',
  business_user: 'Business User',
  repeat_password: 'Passwort wiederholen',
  username: 'Username',
  gender: 'Geschlecht',
  undefined: 'Nicht definiert',
  male: 'Männlich',
  female: 'Weiblich',
  birthday: 'Geburtstag',
  organizer_name: 'Organizer Name',
  tax_code: 'Steuernummer',
  address: 'Address',
  account_holder_name: 'Kontoinhaber',
  iban_number: 'IBAN-Nummer',
  bic: 'BIC',
  description: 'Beschreibung',
  reset_password: 'Passwort zurücksetzen',
  next: 'Weiter',
  resend_code_in: 'Code in {0}:{1} erneut senden',
  country_code: 'Landesvorwahl',
  example: 'Beispiel: ',
  save: 'Speichern',
  profile: 'Profil',
  my_events: 'Meine Events',
  my_tickets: 'Meine Tickets',
  category: 'Kategorie',
  price: 'Preis',
  piece: 'Stück',
  amount: 'Menge',
  group_ticket: 'Gruppen Ticket',
  minimum: 'Min',
  maximum: 'Max',
  vip_ticket: 'Gästeliste',
  vip_password: 'VIP Passwort',
  service_charge: 'Servicegebühren',
  total: 'Gesamt',
  buy: 'Kaufen',
  ticket: 'Ticket',
  refunded: 'Rückerstattung',
  live_event: 'Live Event',
  past_event: 'Past Event',
  payout_amount: 'Auszahlungsbetrag',
  not_paid: 'Nicht Bezahlt',
  already_paid: 'Bereits Bezahlt',
  pending: 'Ausstehend',
  sales_amount: 'Basisumsatz',
  handling_fee: 'Abwicklungsgebühren',
  refund_fee: 'Rückerstattungsgebühr',
  sold_tickets: 'Verkaufte Tickets',
  check_ins: 'Check-Ins',
  sponsoring_quote: 'Sponsoringanfrage',
  support_email_sent: 'E-mail wurde erfolgreich Versendet.',
	support_email_sent_failed: 'E-Mail konnte nicht gesendet werden.',
  add_event: 'Event hinzufügen',
  edit_event: 'Event bearbeiten',
  event_name: 'Name des Events',
  start_date: 'Startdatum',
  end_date: 'Enddatum',
  genre: 'Genre',
  add: 'Hinzufügen',
  select_event_genre: 'Ereignis Genre auswählen',
  yes: 'Ja',
  no: 'Nein',
  dashboard: 'Armaturenbrett',
  sold_out: 'Ausverkauft',
  hide_ticket: 'Ausblenden',
  promo_code: 'Gutscheincode',
  code: 'Code',
  discount: 'Rabatt',
  now: 'Jetzt',
  when_ticket_sales_end: 'Sobald Ticketverkauf endet',
  unlimited: 'Unbegrenzt',
  should_address_be_visible: 'Soll die Adresse sichtbar sein?',
  private: '(Privat)',
  your_email: 'Deine E-Mail',
  price_includes_tax: 'Preisangaben inklusive Steuern',
  pay: 'Zahlen',
  accept: 'Akzeptieren',
  delete_photo: 'Foto löschen',
  which_music_do_you_like: 'Welche Musikrichtung gefällt dir?',
  i_am_new_here: 'Ich bin neu hier',
  remove: 'Entfernen',
  apply: 'Übernehmen',
  apply_code_to: 'Code anwenden auf',
  all_tickets: 'Alle Tickets',
  select_tickets: 'Tickets auswählen',
  event: 'Event',
  drink: 'Getränk',
  add_drinks_menu: 'Getränkekarte hinzufügen',
  edit_drinks_menu: 'Getränkekarte bearbeiten',
  drink_name: 'Name des Getränk',
  paid_online: 'Online bezahlt',
  paid_by_card: 'EC/Kreditkarte',
  paid_by_cash: 'Barzahlung',
  tip: 'Trinkgeld',
  sold_drinks: 'Verkaufte Getränke',
  non_alcohol: 'Alkoholfreies Getränk',
  select_shot: 'Wählen Sie ein Shot',
  select_non_alcohol: 'Wählen Sie ein alkoholfreies Getränk',
  tickets_for_free: 'Du willst kostenlos aufs Event?',
  become_a_promoter: 'Werde jetzt mein Botschafter!',
  for_the_period: 'Für den Zeitraum',
  only_visible_to_me: 'Nur sichtbar für mich und meine Mitarbeiter',
  display_event_count_down: 'Möchtest du auf der Website einen Event-Countdown anzeigen?',
  export: 'Exportieren',
  export_buyers: 'Käufer Exportieren',
  export_followers: 'Followers Exportieren',
  only_few_tickets_available: '🔥 Nur noch wenige Tickets übrig',
  show_or_hide_organizer: 'Ein- und Ausblenden des eigenen Zugangs',
  import_drinks: 'Getränkekarte importieren',
  duplicate: 'Duplizieren',
  select_drink_category: 'Getränkekategorie auswählen',
  enter_new_event_info: 'Neue Veranstaltungsinformationen eingeben',
  select_event: 'Event auswählen',
  add_employee: 'Mitarbeiter hinzufügen',
  employee_name: 'Mitarbeiter Name',
  all: 'Alle',
  invoice: 'Rechnung',
  credit_for_commission_services_see_customer_number: 'Gutschrift für Provisionsleistungen siehe Kundennummer',
  invoice_number: 'Rechnungsnummer',
  payout: 'Auszahlung',
  the_handling_fee_includes_tax: 'Die Abwicklungsgebühr enthält 19% Umsatzsteuer.',
  credit_made_in_few_days: 'Die Gutschrift erfolgt in den nächsten Tagen auf das angegebene Konto.',
  payouts: 'Teilvorabauszahlung & Auszahlung',
  post_date: 'Buchungsdatum',
  paid: 'Bezahlt',
  day: 'Tag',
  days: 'Tagen',
  hour: 'Stunde',
  hours: 'Stunden',
  minute: 'Minute',
  minutes: 'Minuten',
  second: 'Sekunde',
  seconds: 'Sekunden',
  transaction_overview_online_payment: 'Transaktionsübersicht/Online getätige Zahlungen',
  date: 'Datum',
  accept_ticket: 'Annehmen',
  tag: 'Ergänzen',
  set_new_tag: 'Weiteres Event hinzufügen',
  join_now: 'Beitreten',
  multiple_days_ticket: 'Mehrere Tagestickets',
  event_days: 'Tage',
  information_about_organizer: 'Informationen zum Veranstalter',

  // Desc
  desc_home_buy_ticket: 'Wir machen es dir einfach!\nKaufe mit einem Klick dein TICKET für Events in deiner Stadt!\nErlebe und teile Deine unvergesslichen Momente auf eventbox!',
  desc_organizer_bank_account: 'Wenn Sie Tickets für unsere Veranstaltung oder Coupons für ihr Gewerbe über unsere Plattform verkaufen möchten, geben Sie hier bitte Ihre Bankdaten ein, damit wir ihnen ihre Umsätze überweisen können. Andernfalls müssen Sie kein Bankkonto angeben.',
  desc_want_sell_tickets_for_your_event: 'Möchtest du Tickets für dein Event verkaufen oder Cupons für dein Gewerbe?',
  desc_for_channel: 'Sei ein Teil unseres exklusiven Botschafter-Teams!\nDu kannst vergünstigte Tickets an deine Freunde verkaufen und im Gegenzug fantastische Prämien wie Freikarten oder die einmalige Gelegenheit, eine Backstagetour zu erleben, bekommen.\nVerpasse nicht die Chance, Teil dieses aufregenden Teams zu sein.\n\nRegistriere dich jetzt hier und schalte dir dein Ticket for FREE frei!',
  desc_for_employee_duplication: 'Bitte aktualisieren Sie die entsprechenden Mitarbeiterzugänge.\nErsetzen Sie nun alte Zugänge mit neue Mitarbeiterzugänge.',
  desc_enter_email_exactly_required_for_next_login: 'Bitte geben Sie Ihre korrekte E-Mail-Adresse ein, da diese für die nächste Anmeldung erforderlich ist.',
  desc_sent_temporary_password_to_email: 'Wir haben das vorläufige Anmeldepasswort an deine E-Mail [{0}] gesendet.\nBitte überprüfe dein E-Mail-Postfach (ggf. auch den Spam-Ordner) und melde dich in der Eventbox-App an.',
  desc_login_app_with_username: 'Bitte melde dich in der eventbox App mit deiner deinem Benutzernamen [{0}] an.',
  desc_login_app_with_email_or_username: 'Bitte melde dich in der eventbox App mit deiner E-Mail-Adresse [{0}] oder deinem Benutzernamen [{1}] an.',
  desc_you_already_registered_with_email_temporary_password_sent_at_: 'Sie haben sich bereits mit dieser E-Mail registriert [{0}].\nDas temporäre Login-Passwort wurde Ihnen {1} per E-Mail gesendet.',
  desc_assigned_vip_ticket_unlock_all: 'Dem Gast sind {0} VIP-Tickets zugewiesen. Möchten Sie alle Tickets freischalten?',

  // Alert
  alert_success: 'Erfolgreich',
  alert_unknown_error: 'Ein unbekannter Fehler ist aufgetreten.',
  alert_unexpected_error: 'Ein unerwarteter Fehler ist aufgetreten.',
  alert_required_field: '{0} ist Pflichtfeld.',
  alert_please_wait: 'Bitte warten ...',
  alert_sign_in: 'Bitte einloggen.',
  alert_sign_in_again: 'Bitte neu anmelden.',
  alert_incorrect_password: 'Falsches Passwort',
  alert_your_account_has_been_blocked: 'Dein Konto ist gesperrt. Kontaktiere den Kundenservice.',
  alert_your_account_has_been_deleted: 'Dein Account wurde gelöscht.',
  alert_this_account_has_been_deleted: 'Dieser Account wurde gelöscht.',
  alert_this_event_has_been_deleted: 'Dieses Event wurde gelöscht.',
  alert_email_already_exist: 'Diese Emailadresse existiert bereits.',
  alert_phone_number_already_exist: 'Diese Mobilnummer existiert bereits.',
  alert_username_already_exist: 'Dieser Username existiert bereits.',
  alert_email_not_exist: 'Kein User mit dieser Emailadresse gefunden.',
  alert_phone_not_exist: 'Kein User mit dieser Mobilnummer gefunden.',
  alert_username_not_exist: 'Kein User mit diesem Usernamen gefunden.',
  alert_closed_by_user: 'Vom Benutzer geschlossen.',
  alert_min_characters: 'Bitte gib mindestens {0} Zeichen ein in die {1}.',
  alert_special_character_not_allowed: 'Sonderzeichen sind nicht erlaubt.',
  alert_passwords_not_match: 'Passwörter stimmen nicht überein.',
  alert_emails_not_match: 'E-Mails stimmen nicht überein.',
  alert_invalid_email: 'Ungültige E-Mail-Adresse.',
  alert_invalid_iban_number: 'Ungültige IBAN-Nummer.',
  alert_failed_to_send_confirm_email: 'Fehler beim Senden der Bestätigungsemail an {0}.',
  alert_incorrect_verification_code: 'Falscher Verifikationscode.',
  alert_over_max_account_per_phone: 'Mit einer Mobilnummer kannst du maximal 5 Konten erstellen.',
  alert_pending_withdraw: 'Deine Auszahlung steht noch aus.\nDu kannst eine neue Auszahlung veranlassen, nachdem die ausstehende abgeschlossen ist.',
  alert_max_50_percent: 'Du kannst maximal 50 % deines Guthabens abheben.',
  alert_over_balance: 'Du kannst nicht mehr abheben als ',
  alert_no_ticket: 'Es sind nur noch {0} tickets übrig.',
  alert_you_can_only_buy_1_vip_ticket: 'Du kannst nur ein VIP Ticket kaufen.',
  alert_you_can_only_buy_max__tickets: 'Du kannst maximal {0} Tickets kaufen.',
  alert_you_can_only_buy_max__tickets_with_promo_code: 'Mit diesem Gutscheincode du kannst maximal {0} Tickets kaufen.',
  alert_sent_verification_code: 'Wir haben den Bestätigungscode an die von dir eingegebene Email gesendet.\nBitte überprüfe auch deinen Spamordner, falls du keine Email in deinem Posteingang hast.',
  alert_unapproved_organizer: 'Vielen Dank für Deine Anmeldung bei eventbox.\nUnser Authentifizierungsteam überprüft deine Organisatorenanfrage.\nDies kann aufgrund der aktuellen Situation einige Zeit dauern.\nFalls deine Registrierung als Veranstalter nicht möglich ist, wirst du als Einzelbenutzer registriert.\nWenn du immer noch der Meinung bist, dass du ein Veranstalter ist, kannst du dich per E-Mail an unseren Support wenden info@eventboxde.com',
  alert_select_photo: 'Bitte wählen Sie ein Foto aus.',
  alert_select_start_date: 'Bitte wählen Sie das Startdatum aus.',
  alert_select_end_date: 'Bitte wählen Sie das Enddatum aus',
  alert_end_date_must_be_greater_than_start_date: 'Das Startdatum muss for dem Enddatum liegen',
  alert_select_address: 'Bitte wählen Sie die Adresse aus.',
  alert_select_genre: 'Bitte wählen Sie das Genre aus.',
  alert_select_category: 'Bitte wählen Sie das Kategorie aus.',
  alert_only_select_2_categories_in_max: 'Du kannst maximal 2 Kategorien gleichzeitig auswählen',
  alert_success_buy_ticket: 'Vielen Dank für deinen Einkauf bei eventbox!\nDeine E-Tickets findest du in der App.',
  alert_minimum_must_be_less_than_: 'Das Minimum muss kleiner oder gleich sein {0}.',
  alert_maximum_must_be_less_than_: 'Das Maximum muss kleiner oder gleich sein {0}.',
  alert_maximum_must_be_grater_minimum: 'Das Maximum muss größer oder gleich dem Minimum sein.',
  alert_add_event_tickets: 'Bitte fügen Sie Kategorie und Preisoptionen hinzu.',
  alert_added_successfully: 'Erfolgreich hinzugefügt.',
  alert_updated_successfully: 'Erfolgreich geupdated.',
  alert_deleted_successfully: 'Erfolgreich gelöscht.',
  alert_wait_for_ticket: 'Die Zahlung steht jetzt aus.\nBitte warten Sie einige Minuten und überprüfen Sie Ihr Ticket erneut.\nWenn Sie das Ticket unter "Meine Tickets" im Profil nicht finden, kontaktieren Sie uns per Mail: info@eventboxde.com',
  alert_correct_promo_code: '{0} eingelöst. Ein Rabatt von {1}% ist jetzt verfügbar.',
  alert_incorrect_promo_code: 'Leider wurde diese Gutscheincode nicht erkannt.',
  alert_duplicate_promo_code: 'Der eingegebene Code existiert bereits. Bitte versuchen Sie es mit einem anderen erneut.',
  alert_select_tickets_for_promo_code: 'Bitte wählen Sie für den Gutscheincode mindestens eine Ticketkategorie aus.',
  alert_add_drinks_menu: 'Bitte fügen Sie das Getränkekarte aus.',
  alert_select_shot: 'Bitte wählen Sie ein Shot.',
  alert_select_non_alcohol: 'Bitte wählen Sie ein alkoholfreies Getränk.',
  alert_assign_inventory_to_employees: 'Du kannst nun deinen Warenbestand den Bars/Mitarbeitern zuweisen.',
  alert_not_valid_event: 'Entschuldigung, dieses Ereignis ist nicht gültig, um die Getränke zu importieren',
  alert_employee_name_exist: 'Dieser Mitarbeiter Name: {0} existiert bereits.',
  alert_login_to_app_first: 'Melde dich in der App zunächst an.',
  alert_link_is_expired: 'Der Link ist bereits abgelaufen. Bitte wende dich beim Veranstalter.',
  alert_link_is_already_activated: 'Der Link ist bereits freigeschaltet. Ein erneuter Zugriff auf die Gästeliste ist nicht möglich.',
  alert_tag_is_expired: 'Dieser Eventtag ist bereits abgelaufen.',
  alert_set_new_tag_instruction: 'Beim Hinzufügen eines neuen Events wird das aktuelle Event und die Bestellungen automatisch abgeschlossen und eine Auszahlung angefordert.\nDie Bestellung wird mit dem neuen Event neu gestartet, und die Anfangsbestände bleiben unverändert.\nWenn Sie OK klicken, kann dieser Vorgang nicht rückgängig gemacht werden.',
  alert_request_payout_after_end_event: 'Den Antrag zur Auszahlung können Sie erst nach der Veranstaltung veranlassen.',
  alert_information_about_organizer: '<b>Hinweis:</b>\nDie eventbox ist nicht selbst Veranstalter der angebotenen Veranstaltung. Die Veranstaltung wird durch den Veranstalter durchgeführt, der auch Aussteller der Tickets ist. Die eventbox hat es lediglich übernommen, die Tickets als Kommissionär für den Veranstalter zu vertreiben.\n\n<b>Veranstalter:</b>\n{0}, {1}',

  // Confirm
  confirm_logout: 'Möchtest du dich jetzt ausloggen?',
  confirm_delete: 'Bist du sicher das du löschen möchtest?',
  confirm_want_sell_tickets_for_your_event: 'Möchtest du Tickets für dein Event verkaufen oder Cupons für dein Gewerbe?',
  confirm_organizer: 'Sie sind Gewerbetreibender, Veranstalter, Künstler (Sänger, DJ, Produzent usw.) oder ein gastronomisches Unternehmen (Konzertsaal, Kino, Theater, Restaurant, Club usw.)?\nErstellen sie jetzt ihren eigenen Account, verkaufen Sie Tickets oder Cupons, profitieren sie von vielen Vorteilen, erzielen sie außerordentliche Verkäufe und vieles mehr..\nJetzt registrieren.',
  confirm_edit_card_info: 'Du musst deine Bankdaten in deinem Profil speichern. Möchtest du diese jetzt hinzufügen?',
};